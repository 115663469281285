import {getCurrentInstance, reactive, toRefs, onBeforeMount, onMounted, defineComponent, nextTick, computed} from 'vue';
import LayerUtil,{ILayerDataObj} from "@/views/sysviews/layer/layerUtil";
import defaultLayer from '../../../assets/layer/itemPic/default.png';
export default defineComponent ({
    name: "layer",
    proxyInst:{},
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;

        let dataObj:ILayerDataObj=reactive<ILayerDataObj>({
            utilInst:{} as any,
            refMap:new Map<string, any>(),
            userMenus:sessionStorage.getItem('userMenus')?JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem('userMenus') as string)):'',
            layerData:[],
            layerDataBak:[],
            curOperate:null,
            //主页layer中的文件夹单击弹出框属性设置
            dialogOpts: {
                moveVisible:false,
                folderName:'',
                moveType:0
            },
            //当前正在操作的主页layer中的文件夹
            folderOpts:{
                folderItem:null,
                folderChild:[],
                visibility:'hidden',
                borderCls:'none'
            },
            layerContentOuterOpts:{
                opacity:1
            },
            //主页layer和弹出folder中对象的拖动排序对象
            sortableInst:{
                layerContentSortable:null,
                folderContentSortable:null
            },
            //主页文件夹名称修改
            newFolder:{
                newFoldName:''
            },
            searchContent:'',
            iconShowTypeFlag:localStorage.getItem('iconShowTypeFlag')=='false'?false:true,
            layerAnimation:localStorage.getItem('layerAnimation')=='false'?false:true,
            defaultImg:defaultLayer,
            otherParams:{
                liNum:0
            }
        })
        onBeforeMount(()=>{
            //li的宽高是256、110，计算整个屏幕需要多少个li
            let wNum=window.innerWidth/256+1;
            let hNum=window.innerHeight/110+1;
            dataObj.otherParams.liNum=parseInt(String(wNum * hNum));

            dataObj.utilInst=new LayerUtil(proxy,dataObj);
        })
        onMounted(async ()=>{
            await nextTick( ()=>{
                dataObj.utilInst.initData(true);
                dataObj.utilInst.createLayerContentSortable();

                let lis:any=document.querySelectorAll('.over > ul > li');
                for(let i=0;i<lis.length;i++){
                    lis[i].style = 'opacity:0;transition: all 0.05s '+i*0.05+'s;';
                }
                let time=parseInt(String(lis.length * 0.05 + 2800));
                setTimeout(()=>{dataObj.otherParams.liNum=0;},time);//跑完动画之后，把div高度干为0
            })
        })

        //点击layer主页上的节点，跳转到main主页对应菜单
        const layerItemClick=async (item:any,flag:boolean)=>{
            //如果当前状态是：点击layer主页上的文件夹，打开层展示文件夹下的菜单项，那么这个时候触发了layer主页上节点的点击事件，说明只想关闭弹出层，
            //点击了主页，不小心点击到了主页上的操作项，这个时候，只把弹出层给关闭就行了，没有必要触发该项的点击事件真正意图
            if(dataObj.folderOpts.visibility=='visible'){
                mainDivHandler();
                return ;
            }
            await dataObj.utilInst.doItemClick(item,flag);
        }
        //弹出层里面操作项的点击事件，为什么不和上面的事件公用呢，在上方事件里面有说明
        const layerFolderItemClick=async(item:any,flag:boolean)=>{
            await dataObj.utilInst.doItemClick(item,flag);
        }
        //layer文件夹名称双击事件
        const foldDbClick=(item:any)=>{
            item.editFolderName=true;
            dataObj.newFolder.newFoldName=item.title;
            //有时候双击完成，这个时候输入框出现，但是它并未得到焦点，你点击其它地方就不存在失去焦点这一说，于是回车事件、失去焦点事件都不会触发，
            //所以双击完成之后还要让输入框获得焦点，注意：输入框是动态创建出来的，所以把它放到nextTick,否则输入框可能还没有创建出来
            nextTick(()=>{
                dataObj.refMap.get(item.id).focus();
            })
        }
        //修改文件夹名称输入框失去焦点事件
        const editFolderNameBlur=async (item:any)=>{
            if(dataObj.newFolder.newFoldName){
                await dataObj.utilInst.editFoldName(item);
                dataObj.newFolder.newFoldName='';
            }
        }
        //修改文件夹名称输入框回车事件
        const editFolderNameEnter=async (item:any)=>{
            if(dataObj.newFolder.newFoldName){
                await dataObj.utilInst.editFoldName(item);
                dataObj.newFolder.newFoldName='';
            }
        }
        //确定合并两个节点事件
        const mergeHandler=async ()=>{
            dataObj.dialogOpts.moveVisible=false;
            const loading = proxy.$loading({lock: true,text: proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            let draggedId=dataObj.curOperate.dragged.dataset.id;//源id
            let relatedId=dataObj.curOperate.related.dataset.id;//目的id
            if(dataObj.dialogOpts.moveType==0){//移动
                let oldIndex=dataObj.utilInst.findIndex(draggedId);
                let newIndex=dataObj.utilInst.findIndex(relatedId);
                [dataObj.layerData[newIndex],dataObj.layerData[oldIndex]]=[dataObj.layerData[oldIndex],dataObj.layerData[newIndex]];
            }else{//合并
                let draggedItem=dataObj.utilInst.findLayerDataItemById(draggedId);//源
                let relatedItem=dataObj.utilInst.findLayerDataItemById(relatedId);//目的
                let layerData=dataObj.layerData;
                if(relatedItem.children){//1、如果目的本来就是一个文件夹,则把源的内容添加到目的就可以了
                    for(let i=0;i<layerData.length;i++){//1-1、循环layerData，找到目的，然后把源添加到目的里面
                        if(layerData[i]['id']==relatedId){
                            if(draggedItem.children){//1-2、如果源也是一个文件夹，则把源的所有内容都添加到目的
                                for(let j=0;j<draggedItem.children.length;j++){
                                    let addItem=draggedItem.children[j];
                                    addItem.parentId=relatedId;
                                    relatedItem.children.push(addItem);
                                }
                            }else{//1-3、如果源不是文件夹，则不用循环了
                                draggedItem.parentId=relatedId;
                                relatedItem.children.push(draggedItem);
                            }
                            break;
                        }
                    }
                    dataObj.utilInst.cfgItemTitle(relatedId,dataObj.dialogOpts.folderName);//1-4、设置合并后文件夹名称
                    dataObj.utilInst.deleteItemFromLayerData(draggedId);//1-5、从layerData中删除源
                }else if(draggedItem.children){//2、如果目的不是文件夹，但是源是文件夹，则把目的添加到源里面
                    for(let i=0;i<layerData.length;i++){//2-1、循环layerData，找到源
                        if(layerData[i]['id']==draggedId){
                            relatedItem.parentId=draggedId;
                            layerData[i].children.push(relatedItem);
                            break;
                        }
                    }
                    dataObj.utilInst.cfgItemTitle(draggedId,dataObj.dialogOpts.folderName);//2-2、设置合并后文件夹名称
                    dataObj.utilInst.deleteItemFromLayerData(relatedId);//2-3、从layerData中删除目的
                }else{//3、如果两个都不是文件夹，则创建一个文件夹
                    let genId=await utils.Api.getNewId();
                    //3-1、创建新对象
                    let folderItem:any={id:genId,parentId:'-',title:dataObj.dialogOpts.folderName};
                    folderItem.children=[];
                    //3-2、往新对象中加入源
                    draggedItem.parentId=genId;
                    folderItem.children.push(draggedItem);
                    //3-3、往新对象中加入目的
                    relatedItem.parentId=genId;
                    folderItem.children.push(relatedItem);
                    //3-4、找出目的在layerData中的位置，然后把新对象放到这个位置上面
                    let relatedIndex=dataObj.utilInst.findIndex(relatedId);
                    dataObj.layerData.splice((relatedIndex+1), 0, folderItem);

                    dataObj.utilInst.deleteItemFromLayerData(draggedId);//3-5、从layerData中删除源
                    dataObj.utilInst.deleteItemFromLayerData(relatedId);//3-6、从layerData中删除目的
                }
            }
            loading.close();
            dataObj.curOperate={};//操作完成，清除信息
           await dataObj.utilInst.saveUserLayout();
        }
        //取消合并或拖动节点事件
        const cancelMergeHandler=async ()=>{
            dataObj.dialogOpts.moveVisible=false;
            dataObj.curOperate={};//操作完成，清除信息
        }
        //主页点击事件，把弹出层给影藏掉，恢复主页的透明度
        const mainDivHandler=()=>{
            if(dataObj.folderOpts.visibility=='visible'){
                dataObj.folderOpts.visibility='hidden';
                dataObj.layerContentOuterOpts.opacity=1;
            }
        }
        //移动或者合并单选radio改变事件
        const radioChangeHandler=(val:any)=>{
            //如果是合并，设置合并后文件夹默认名称
            if(val==1){
                let draggedTitle=dataObj.curOperate.dragged.dataset.title;
                let relatedTitle=dataObj.curOperate.related.dataset.title;
                dataObj.dialogOpts.folderName=draggedTitle+'-'+relatedTitle;
            }
        }
        //退出登录
        const loginOutHandler=()=>{
            utils.Tools.configBox({
                message: proxy.$t('login.loginOutNote'),
                sureFn: async () => {
                    proxy.$loading({lock: true,text: proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                    //退出登录的时候，只需要把userInfo去掉，不能把token去掉，否则报错
                    sessionStorage.removeItem("userInfo");
                    let res = await utils.Api.loginOut();
                    if(res.result){
                        utils.Tools.success({message: proxy.$t('login.loginOutMsg')});
                        location.reload();
                    }else{
                        utils.Tools.error();
                    }
                }
            });
        }
        const iconShowTypeHandler=(val:any)=>{
            localStorage.setItem('iconShowTypeFlag',val);
            dataObj.iconShowTypeFlag=val;
        }
        const layerAnimationHandler=(val:any)=>{
            localStorage.setItem('layerAnimation',val);
            dataObj.layerAnimation=val;
        }
        //---------------------------computed---------------------------
        //动态css。
        //先把一个数组里面全部填充为false，然后随机生成一个true，这样就只会启用一个动画css了
        const animateClass=computed(()=>{
            return (params:any) => {
                //如果顶部设置过不启用，则只返回基本class（没有设置代表启用）
                if(localStorage.getItem('layerAnimation')=='true')return Object.assign(utils.UtilPub.randomLayerAnimate(),params);
                else return params;
            };
        })

        return{
            ...toRefs(dataObj),layerItemClick,layerFolderItemClick,foldDbClick,editFolderNameBlur,editFolderNameEnter,mergeHandler,
            cancelMergeHandler,mainDivHandler,radioChangeHandler,loginOutHandler,iconShowTypeHandler,layerAnimationHandler,animateClass
        }
    },
    components: {}
});